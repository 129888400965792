import { render, staticRenderFns } from "./fake_search_input.vue?vue&type=template&id=f5f79d4c&scoped=true"
import script from "./fake_search_input.vue?vue&type=script&lang=js"
export * from "./fake_search_input.vue?vue&type=script&lang=js"
import style0 from "./fake_search_input.vue?vue&type=style&index=0&id=f5f79d4c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5f79d4c",
  null
  
)

export default component.exports